<template>
  <div style="min-height: 750px;">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['shoplist'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row style="margin-bottom: 10px;">
          <b-col sm="12" lg="6"> </b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
            <b-button size="sm" @click="f_newUserShop()" variant="primary">
              {{ StoreLangTranslation.data['new_user_shop'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="12">
            <b-form-input id="search" v-model="d_searchText" :placeholder="StoreLangTranslation.data['write_for_filtering'][StoreLang]"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="2">
            <strong> Id </strong>
          </b-col>
          <b-col sm="12" lg="2">
            <strong> {{ d_usrShpWdm['0']['parameters']['2']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="2">
            <strong> {{ d_usrShpWdm['0']['parameters']['1']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="2">
            <strong> {{ d_usrShpWdm['0']['parameters']['0']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="2">
            <strong> {{ d_usrShpWdm['0']['parameters']['3']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <!--           <b-col sm="12" lg="1">
            <strong> {{ d_usrShpWdm['0']['parameters']['4']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="1">
            <strong> {{ d_usrShpWdm['0']['parameters']['5']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="1">
            <strong> {{ d_usrShpWdm['0']['parameters']['6']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="1">
            <strong> {{ d_usrShpWdm['0']['parameters']['7']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="1">
            <strong> {{ d_usrShpWdm['0']['parameters']['8']['name']['translation'][StoreLang] }} </strong>
          </b-col>
 -->
          <b-col sm="12" lg="1"> </b-col>
        </b-row>
        <b-row v-for="(usr_shp, usr_shp_ind) in d_usrShpList" v-if="f_searchUserShop(usr_shp)" @mouseover="d_hoverIndex = usr_shp_ind" @mouseleave="d_hoverIndex = ''" :style="StoreDevice.isMobile ? 'cursor: pointer; margin: 10px; border-bottom: solid 1px #dcdcdc;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc;'">
          <b-col sm="12" lg="2">
            <small style="color: blue;">{{ usr_shp.client_id }}</small>
          </b-col>
          <b-col sm="12" lg="2">
            <template v-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['2'] && usr_shp.data['0']['2']['lang'] && usr_shp.data['0']['2']['lang'][StoreLang]">
              <img :src="usr_shp.data['0']['2']['lang'][StoreLang]" style="width: 50%">
            </template>
            <template v-else-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['2'] && usr_shp.data['0']['2']['val']">
              <img :src="usr_shp.data['0']['2']['val']" style="width: 100%">
            </template>
          </b-col>
          <b-col sm="12" lg="2">
            <template v-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['1'] && usr_shp.data['0']['1']['lang'] && usr_shp.data['0']['1']['lang'][StoreLang]">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['1']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['1'] && usr_shp.data['0']['1']['val']">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['1']['val'] }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="2">
            <template v-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['0'] && usr_shp.data['0']['0']['val']['translation']">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['0']['val']['translation'][StoreLang] }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="2">
            <template v-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['3'] && usr_shp.data['0']['3']['lang'] && usr_shp.data['0']['3']['lang'][StoreLang]">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['3']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['3'] && usr_shp.data['0']['3']['val']">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['3']['val'] }}</span>
            </template>
          </b-col>
          <!-- 
          <b-col sm="12" lg="1">
            <template v-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['4'] && usr_shp.data['0']['4']['lang'] && usr_shp.data['0']['4']['lang'][StoreLang]">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['4']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['4'] && usr_shp.data['0']['4']['val']">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['4']['val'] }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="1">
            <template v-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['5'] && usr_shp.data['0']['5']['lang'] && usr_shp.data['0']['5']['lang'][StoreLang]">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['5']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['5'] && usr_shp.data['0']['5']['val']">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['5']['val'] }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="1">
            <template v-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['6'] && usr_shp.data['0']['6']['lang'] && usr_shp.data['0']['6']['lang'][StoreLang]">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['6']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['6'] && usr_shp.data['0']['6']['val']">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['6']['val'] }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="1">
            <template v-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['7'] && usr_shp.data['0']['7']['lang'] && usr_shp.data['0']['7']['lang'][StoreLang]">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['7']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['7'] && usr_shp.data['0']['7']['val']">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['7']['val'] }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="1">
            <template v-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['8'] && usr_shp.data['0']['8']['lang'] && usr_shp.data['0']['8']['lang'][StoreLang]">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['8']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="usr_shp.data && usr_shp.data['0'] && usr_shp.data['0']['8'] && usr_shp.data['0']['8']['val']">
              <span :style="d_hoverIndex === usr_shp_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ usr_shp.data['0']['8']['val'] }}</span>
            </template>
          </b-col>
           -->
          <b-col sm="12" lg="1">
            <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
              <template slot="button-content">
                işlem
              </template>
              <b-dropdown-item v-if="d_user && d_user.auth.indexOf('admin') !== -1" @click="f_editThisUserShop(usr_shp.client_id)"><i class="fa fa-edit"></i> {{ StoreLangTranslation.data['edit'][StoreLang] }} </b-dropdown-item>
              <b-dropdown-item v-if="d_user && d_user.auth.indexOf('admin') !== -1" @click="f_newUserShopBundle(usr_shp.client_id)"><i class="fa fa-plus"></i> {{ StoreLangTranslation.data['create_new_product_bundle'][StoreLang] }} </b-dropdown-item>
              <b-dropdown-item v-if="d_user && d_user.auth.indexOf('admin') !== -1" @click="f_userShopBundleList(usr_shp.client_id)"><i class="fa fa-list"></i> {{ StoreLangTranslation.data['user_shop_bundle_list'][StoreLang] }} </b-dropdown-item>
              <b-dropdown-item v-if="d_user && d_user.auth.indexOf('admin') !== -1" @click="f_userShopOrderList(usr_shp.client_id)"><i class="fa fa-list"></i> {{ StoreLangTranslation.data['user_shop_order_list'][StoreLang] }} </b-dropdown-item>
              <b-dropdown-item v-if="d_user && d_user.auth.indexOf('admin') !== -1" @click="f_openShopBundleUpdateByExcelModal(usr_shp.client_id)"><i class="fa fa-list"></i> {{ StoreLangTranslation.data['shop_bundle_update_by_excel'][StoreLang] }} </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-modal v-if="d_systemUpdateByExcelModal.show" id="d_selectExcelData_show" v-model="d_systemUpdateByExcelModal.show" scrollable hide-footer :title="StoreLangTranslation.data['process'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <b-form-file
                @input="f_selectExcelDocument()"
                style="height: 100px; border: dotted 2px #25bcff; background: #daf9ff;"
                v-model="d_tmpFile"
                :accept="f_createAcceptedDocumentTypes()"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
            >
              <img src="@/icon/1530793.png" style="width: 2em;">
            </b-form-file>
          </b-col>
        </b-row>
      </p>
      <p>
        <b-row>
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
            <b-button variant="outline-primary" @click="f_shopBundleUpdateByExcel()" :disabled="d_loadingSystemUpdate">
              <i class="fa fa-save"></i>
              {{ StoreLangTranslation.data['save'][StoreLang] }}
              <b-spinner v-if="d_loadingSystemUpdate" variant="primary" label="Spinning"></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { data as system_menu_list } from '@/options/system_menu_list';
import store from '@/store';
import UserShopService from '@/services/user_shop';
import { data as usr_shp } from '@/options/usr_shp';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shop_list',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_loadingSystemUpdate: false,
      d_systemUpdateByExcelModal: {
        'show': false,
        'shop_id': ''
      },
      d_excelFileBase64: '',
      d_tmpFile: null,
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_usrShpList: [],
      d_usrShpWdm: usr_shp,
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.d_user && this.d_user.username) {
      this.f_userShopList();
    }
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_usrShpWdm);
  },
  methods: {
    f_openShopBundleUpdateByExcelModal: function (shop_id) {
      this.d_systemUpdateByExcelModal.shop_id = shop_id;
      this.d_systemUpdateByExcelModal.show = true;
    },
    f_shopBundleUpdateByExcel: function () {
      if (this.d_excelFileBase64) {
        this.d_loadingSystemUpdate = true;
        let query = 'user_shop_client_id=' + this.d_systemUpdateByExcelModal.shop_id;
        let data = { 'shop_bundle_excel_file': this.d_excelFileBase64 };
        UserShopService.shop_bundle_update_by_excel(query, data)
          .then(resp => {
            this.d_loadingSystemUpdate = false;
            this.d_systemUpdateByExcelModal.show = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
              // location.reload();
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      }
    },
    f_selectExcelDocument: function () {
      if (this.d_tmpFile) {
        // console.log(this.d_tmpFile);
        let file_name = this.d_tmpFile.name;
        let arr = this.d_tmpFile.name.split('.');
        let fileExtension = arr[arr.length - 1];
        if (['xlsx'].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_extension_type_is_not_eligible'][this.StoreLang]);
          return;
        }
        if (this.d_tmpFile.size > 10485760) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_size_is_not_eligible'][this.StoreLang], ' : ', this.d_tmpFile.size);
          return;
        }
        var reader = new FileReader();
        var vm = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          // console.log(reader.result);
          // console.log('page_info_index ', page_info_index);
          // console.log('page_info_detail_index ', page_info_detail_index);
          // console.log('vm.d_frdWebunitData.page_info ', vm.d_frdWebunitData.page_info);
          vm.d_excelFileBase64 = reader.result;
          vm.$forceUpdate();
          // vm.f_applyFrontendSettings();
        }, false);
        reader.readAsDataURL(this.d_tmpFile);
        this.d_tmpFile = null;
        this.$forceUpdate();
      }
    },
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ['xlsx'];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    f_getRoutePath: function (target_route_name_en) {
      let route_path = '';
      for (let i in system_menu_list) {
        if (system_menu_list[i].route_name.en == target_route_name_en) {
          route_path = system_menu_list[i].route_path[this.StoreLang];
        }
        if (!route_path) {
          if (system_menu_list[i].list && system_menu_list[i].list.length) {
            for (let k in system_menu_list[i].list) {
              if (system_menu_list[i].list[k].route_name.en == target_route_name_en) {
                route_path = system_menu_list[i].list[k].route_path[this.StoreLang];
                break;
              }
            }
          }
        }
        if (route_path) {
          break;
        }
      }
      return route_path;
    },
    f_newUserShop: function () {
      this.$router.push({ path: this.f_getRoutePath('user_shop_record'), query: {} });
    },
    f_newUserShopBundle: function (user_shop_client_id) {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_record'), query: { 'user_shop_client_id': user_shop_client_id } });
    },
    f_userShopBundleList: function (user_shop_client_id) {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_list'), query: { 'user_shop_client_id': user_shop_client_id } });
    },
    f_userShopOrderList: function (user_shop_client_id) {
      this.$router.push({ path: this.f_getRoutePath('user_shop_order_list'), query: { 'user_shop_client_id': user_shop_client_id } });
    },
    f_editThisUserShop: function (user_shop_client_id) {
      let route_path = this.f_getRoutePath('user_shop_record');
      // console.log('route_path : ', route_path);
      this.$router.push({ path: route_path, query: { 'user_shop_client_id': user_shop_client_id } });
    },
    f_searchUserShop: function (usr_shp) {
      let res = false;
      if (this.d_searchText) {
        if (usr_shp.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_userShopList: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      UserShopService.user_shop_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_usrShpList = resp.data.list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
  },
  watch: {}
};

</script>

